const EMAIL_PATTERN_STRING =
  '[a-zA-Z0-9_+-]+(\\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,}'

export const EMAIL_PATTERN = new RegExp(`^${EMAIL_PATTERN_STRING}$`)

export const BCC_PATTERN = new RegExp(`^${EMAIL_PATTERN_STRING}( *, *${EMAIL_PATTERN_STRING})*$`)

export const URL_PATTERN =
  /^https?:\/\/([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9()@:%_+.~#?&=-]*)*$/

export const COLOR_CODE_PATTERN = /^#([0-9a-hA-H]{3,4}|[0-9a-hA-H]{6}|[0-9a-hA-H]{8})$/

export const DATE_FORMAT = 'yyyy-MM-dd HH:mm'

export const SHARED_IMAGE_EXPIRES_IN = 60 * 60 * 24 * Number(process.env['NEXT_PUBLIC_SHARED_MEDIA_EXPIRY_DAYS'] ?? 180)

export const EMAIL_FORM_PLACEHOLDER = 'sample@example.com'
