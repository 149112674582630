import { FC } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { PublishedVideoSlide } from '../../orval/loovPublic'
import VideoPlayer from '../needsTests/VideoPlayer'
import VideoSlidePlayerPresentation from './VideoSlidePlayerPresentation'

export type VideoSlidePlayerProps = {
  slide: PublishedVideoSlide
}
const VideoSlidePlayer: FC<VideoSlidePlayerProps> = (props) => {
  return <VideoSlidePlayerPresentation {...props} useIsMobile={useIsMobile} VideoPlayer={VideoPlayer} />
}

export default VideoSlidePlayer
