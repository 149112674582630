import { FC } from 'react'
import FormSlidePlayer from '../FormSlidePlayer/FormSlidePlayer'
import useScenarioPlayer from '../needsTests/ScenarioPlayer/useScenarioPlayer'
import SelectionSlidePlayer from '../SelectionSlidePlayer/SelectionSlidePlayer'
import VideoSlidePlayer from '../VideoSlidePlayer/VideoSlidePlayer'
import ScenarioPlayerPresentation from './ScenarioPlayerPresentation'

const ScenarioPlayer: FC = () => {
  const props = useScenarioPlayer()

  return (
    <ScenarioPlayerPresentation
      {...props}
      VideoSlidePlayer={VideoSlidePlayer}
      SelectionSlidePlayer={SelectionSlidePlayer}
      FormSlidePlayer={FormSlidePlayer}
    />
  )
}

export default ScenarioPlayer
