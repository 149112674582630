import { Box, Button, Circle, Grid, Icon, Progress, useDisclosure } from '@chakra-ui/react'
import { FC, useRef } from 'react'
import { BsPlayCircleFill } from 'react-icons/bs'
import useSuspenseSwr, { immutableOptions } from '../../../hooks/useSuspenseSwr'
import { getLatestPublishedScenario } from '../../../orval/loovPublic'
import SwrKey from '../../commons/swrKey'
import VisualMedia from '../../VisualMedia/VisualMedia'
import ScenarioPlayerModal from './ScenarioPlayerModal'
import ScenarioPlayerProvider from './ScenarioPlayerProvider'

type EmbeddedCodeScenarioPlayerProps = {
  scenarioId: string
}

const EmbeddedCodeScenarioPlayer: FC<EmbeddedCodeScenarioPlayerProps> = (props) => {
  const { data: publishedScenario } = useSuspenseSwr(
    [SwrKey.PUBLISHED_SCENARIOS, { scenarioId: props.scenarioId }],
    () => getLatestPublishedScenario({ scenarioId: props.scenarioId }),
    immutableOptions,
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <Button display="block" boxSize="100%" variant="unstyled" pos="relative" role="group" onClick={onOpen}>
        <VisualMedia
          commonProps={{ src: publishedScenario?.scenarioThumbnail?.url, boxSize: '100%', objectFit: 'cover' }}
        />
        <Box
          pos="absolute"
          top={0}
          left={0}
          w="100%"
          h="100%"
          bg="linear-gradient(to bottom, #0005, #FFF0 30%)"
          transition="0.1s linear"
          _groupHover={{ bg: 'linear-gradient(to bottom, #0003, #FFF1 30%)' }}
        />

        <Circle
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          size="40px"
          bg="white"
          shadow="md"
          p="1px"
          transition="0.1s linear"
          _groupHover={{ w: '43px', h: '43px', shadow: 'lg' }}
        >
          <Icon
            as={BsPlayCircleFill}
            boxSize="100%"
            color="primary.basic"
            transition="0.1s linear"
            _groupHover={{ color: 'primary.lighter' }}
          />
        </Circle>

        <Grid pos="absolute" bottom="0" h="20%" w="100%" placeItems="center">
          <Progress
            w="90%"
            h="4px"
            outline="1px solid"
            outlineColor="white"
            rounded="md"
            colorScheme="primary"
            value={25}
            transition="0.1s linear"
            _groupHover={{
              h: '5px',
            }}
          />
        </Grid>
      </Button>

      {/* Stacking Context を生成するために pos: relative が必要。ないと前面に出てこないことがある。 */}
      <Box pos="relative" zIndex={1e9} ref={containerRef}>
        <ScenarioPlayerProvider publishedScenario={publishedScenario}>
          <ScenarioPlayerModal isOpen={isOpen} onClose={onClose} containerRef={containerRef} />
        </ScenarioPlayerProvider>
      </Box>
    </>
  )
}

export default EmbeddedCodeScenarioPlayer
