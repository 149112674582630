import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { FormDesign } from '../../orval/loovPublic'
import FormSlideInputs from '../FormSlideInputs/FormSlideInputs'
import VisualMedia from '../VisualMedia/VisualMedia'
import { FormSlidePlayerProps } from './FormSlidePlayer'

export type FormSlidePlayerPresentationProps = FormSlidePlayerProps & {
  useIsMobile: typeof useIsMobile
  VisualMedia: typeof VisualMedia
  FormSlideInputs: typeof FormSlideInputs
}

const FormSlidePlayerPresentation: FC<FormSlidePlayerPresentationProps> = (props) => {
  const isMobile = props.useIsMobile()
  const content = props.publishedFormSlide.publishedFormSlideContent
  const onMount = props.onMount

  useEffect(() => {
    onMount()
  }, [onMount])

  return (
    <Stack w="100%" p={['16px', '24px', '32px']} align="center" bg="white" minH="100%">
      {!isMobile && content.design === FormDesign.WITH_THUMBNAIL ? (
        // サムネイル付きレイアウト

        <Stack spacing="32px" w="100%" maxW="800px" data-testid="thumbnail-mode">
          {/* 説明文 */}
          <Text fontSize="20px" fontWeight="bold" data-testid="thumbnail-mode-description-text">
            {content.description}
          </Text>

          <HStack spacing="32px" w="100%" align="start">
            {/* 左半分 */}
            <Stack spacing="16px" flexGrow={1}>
              {/* サムネイル */}
              <props.VisualMedia
                commonProps={{
                  src: content.formThumbnail?.url ?? '',
                  aspectRatio: '16 / 9',
                  objectFit: 'contain',
                  objectPosition: 'left',
                }}
              />

              {/* サムネイル下説明文 */}
              <Text
                fontSize="16px"
                wordBreak="break-all"
                whiteSpace="pre-wrap"
                data-testid="thumbnail-mode-description-under-thumbnail-text"
              >
                {content.descriptionUnderThumbnail}
              </Text>
            </Stack>

            {/* 右半分 */}
            <Box>
              <props.FormSlideInputs
                inputs={content.publishedFormInputs}
                privacyPolicyUrl={content.privacyPolicyUrl}
                privacyPolicyText={content.privacyPolicyText}
                privacyPolicyCheckboxText={content.privacyPolicyCheckboxText}
                buttonText={content.buttonText}
                onSubmit={props.onSubmit}
              />
            </Box>
          </HStack>
        </Stack>
      ) : (
        // 標準レイアウト

        <VStack spacing="32px" maxW="500px" data-testid="default-mode">
          {/* 説明文 */}
          <Text
            fontSize={['17px', '20px']}
            align="center"
            wordBreak="break-all"
            whiteSpace="pre-wrap"
            data-testid="default-mode-description-text"
          >
            {content.description}
          </Text>

          {/* 入力フォーム */}
          <props.FormSlideInputs
            inputs={content.publishedFormInputs}
            privacyPolicyUrl={content.privacyPolicyUrl}
            privacyPolicyText={content.privacyPolicyText}
            privacyPolicyCheckboxText={content.privacyPolicyCheckboxText}
            buttonText={content.buttonText}
            onSubmit={props.onSubmit}
          />
        </VStack>
      )}

      {/* スクロール時のフォーム下部の余白 */}
      <Box h={['16px', '24px', '32px']} />
    </Stack>
  )
}

export default FormSlidePlayerPresentation
