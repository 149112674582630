import { Box, Center, Icon, useBoolean, VStack } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { BsPlayCircle } from 'react-icons/bs'
import videoBg from '../../../public/images/video_bg.webp'
import UnderConstructionIcon from '../../elements/icons/UnderConstruction'
import Loading from '../../elements/Loading'
import H1 from '../../elements/typography/H1'
import useIsMobile from '../../hooks/useIsMobile'
import { VideoStatus } from '../../orval/loovPublic'
import useScenarioPlayer from '../needsTests/ScenarioPlayer/useScenarioPlayer'
import VideoPlayer from '../needsTests/VideoPlayer'
import { VideoSlidePlayerProps } from './VideoSlidePlayer'

export type VideoSlidePlayerPresentationProps = VideoSlidePlayerProps & {
  useIsMobile: typeof useIsMobile
  VideoPlayer: typeof VideoPlayer
}

const VideoSlidePlayerPresentation: FC<VideoSlidePlayerPresentationProps> = ({ slide, useIsMobile, VideoPlayer }) => {
  const {
    playbackRate,
    volume,
    replayEventTarget,
    setCurrentTime,
    setPlaybackRate,
    setVolume,
    goNext,
    onViewStart,
    recordViewingTime,
  } = useScenarioPlayer()
  const [isPlayButtonVisible, setIsPlayButtonVisible] = useBoolean()
  const [isVideoLoading, setIsVideoLoading] = useBoolean(true)
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(null)
  const isMobile = useIsMobile()
  const video = slide.publishedVideoSlideContent.video

  // replay イベントにハンドラーを設定
  useEffect(() => {
    if (!videoElement) return

    const replayHandler = () => (videoElement.currentTime = 0)

    replayEventTarget.addEventListener('replay', replayHandler)
    return () => replayEventTarget.removeEventListener('replay', replayHandler)
  }, [videoElement, replayEventTarget])

  // 再生中にタブやアプリが切り替えられた場合は視聴時間を記録
  useEffect(() => {
    if (!videoElement) return

    const visibilityChangeHandler = () => {
      if (!videoElement.paused && !videoElement.ended && document.visibilityState === 'hidden') {
        recordViewingTime()
      }
    }

    document.addEventListener('visibilitychange', visibilityChangeHandler)
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler)
    }
  }, [videoElement, recordViewingTime])

  // モーダルが閉じられるなど、再生中にアンマウントされた場合は視聴時間を記録
  useEffect(() => {
    if (!videoElement) return

    return () => {
      if (!videoElement.paused && !videoElement.ended) {
        recordViewingTime()
      }
    }
  }, [videoElement, recordViewingTime])

  useEffect(() => {
    if (!videoElement) return

    const playHandler = () => {
      onViewStart()
      setIsPlayButtonVisible.off()
    }
    // 初回再生時のみ、視聴開始を通知
    videoElement.addEventListener('play', playHandler, { once: true })
  }, [videoElement, onViewStart, setIsPlayButtonVisible])

  return video?.status === VideoStatus.AVAILABLE ? (
    <Box pos="relative" w="100%" aspectRatio="16/9">
      <VideoPlayer
        caption={video.caption}
        videoProps={{
          src: video.url + '#t=0.001',
          autoPlay: true,
          playsInline: true,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          // ユーザーのWebサイトに埋め込まれる場合、絶対URLでは静的リソースを読み込めないので dataURL に変換して埋め込む
          backgroundImage: `url("${videoBg}")`,
          backgroundSize: 'cover',
          ref: setVideoElement,
          onTimeUpdate: (e) => setCurrentTime(e.currentTarget.currentTime),
          onRateChange: (e) => setPlaybackRate(e.currentTarget.playbackRate),
          onVolumeChange: (e) => setVolume(e.currentTarget.volume),
          onPause: (e) => !e.currentTarget.ended && recordViewingTime(),
          onEnded: () => goNext(slide.publishedVideoSlideContent),
          onLoadedMetadata: async (e) => {
            const video = e.currentTarget

            // シナリオ全体に設定された再生速度/音量を適用
            video.playbackRate = playbackRate
            video.volume = volume

            // ローディング解除
            setIsVideoLoading.off()
          },
          onAutoPlayFailed: () => !isMobile && setIsPlayButtonVisible.on(),
        }}
      />

      {isVideoLoading && <Loading pos="absolute" top={0} left={0} data-testid="loading" />}

      {/* 自動再生できなかった場合の目立つ再生アイコン */}
      {isPlayButtonVisible && (
        <Center
          pos="absolute"
          top={0}
          left={0}
          w="100%"
          h="100%"
          bg="rgba(170,170,170, 0.7)"
          cursor="pointer"
          onClick={() => videoElement?.play()}
          role="group"
          data-testid="play-button"
        >
          <Box
            pos="relative"
            w="350px"
            h="350px"
            borderRadius="50%"
            bg="rgba(255, 255, 255, 0.4)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _groupHover={{ width: '385px', height: '385px', opacity: 1 }}
            transition="all 0.2s"
          >
            <Icon as={BsPlayCircle} color="white" boxSize="90%" />
          </Box>
        </Center>
      )}
    </Box>
  ) : (
    // 動画素材がない場合
    // 未設定のまま公開したり、公開後に動画素材を削除している可能性がある
    <VStack w="100%" h="100%" justify="center">
      <UnderConstructionIcon h="200px" w="200px" />
      <H1>ただいま準備中です。</H1>
    </VStack>
  )
}

export default VideoSlidePlayerPresentation
