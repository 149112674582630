import { ChakraProvider } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { CSSProperties, ReactNode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { isArray } from 'remeda'
import { embeddedPlayerTheme } from '../../components/commons/theme'

type EmbeddedRenderOptions = {
  parent: HTMLElement
  shadowHostStyle?: CSSProperties
}
export default function embeddedRender(node: ReactNode, { parent, shadowHostStyle }: EmbeddedRenderOptions) {
  // ホストWebサイトのCSSの影響を受けないように、Shadow DOM を作成し、その中にレンダリングする
  const shadowHost = parent.appendChild(document.createElement('div'))
  if (shadowHostStyle) {
    Object.assign(shadowHost.style, shadowHostStyle)
  }
  const shadowRoot = shadowHost.attachShadow({ mode: 'open' })
  const container = shadowRoot.appendChild(document.createElement('div'))
  container.id = `loov-${Math.random().toString(36).slice(-8)}`
  container.style.height = '100%'
  // 埋め込み先のWebサイトのテーマに関係なく、LOOVのコンポーネントは常にライトテーマを適用させる
  container.dataset['theme'] = 'light'

  // CSS をルートヘッダーではなく Shadow DOM 内に適用するためのプラグイン
  const cache = createCache({
    key: 'loov',
    // Shadow DOM 内にCSSを展開する
    // ref) https://github.com/chakra-ui/chakra-ui/issues/2145
    container: shadowRoot,
    // 埋め込み先WebサイトのCSSの影響を受けないようにするため、
    // 詳細度の高いID指定のセレクタに置き換える
    stylisPlugins: [
      (element) => {
        const idSelector = `#${container.id}`
        if (element.type === 'rule' && isArray(element.props)) {
          element.props = element.props.map((prop) => {
            // ルート要素に対するスタイルはホスティング先Webサイトに影響を与えてしまうので、コンテナに適用させるように変更
            if ((['body', 'html', ':host', ':root'] as const).includes(prop)) {
              return idSelector
            }
            // 既に先頭にIDセレクタが付与されている場合は変更しない
            if (prop.startsWith(idSelector)) {
              return prop
            }
            // それ以外のセレクタは、コンテナ以下のものにのみ適用されるように ID セレクタを追加
            const stripped = prop.replaceAll(`${idSelector} `, '')
            return `${idSelector} ${stripped}`
          })
        }
      },
    ],
  })

  // フォントを追加
  if (!document.getElementById('loov-font')) {
    const link = document.head.appendChild(document.createElement('link'))
    link.id = 'loov-font'
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = `https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap`
  }

  createRoot(container).render(
    <CacheProvider value={cache}>
      <ChakraProvider theme={embeddedPlayerTheme}>
        <Suspense fallback={null}>{node}</Suspense>
      </ChakraProvider>
    </CacheProvider>,
  )
}
