import { FC } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { PublishedFormSlide } from '../../orval/loovPublic'
import FormSlideInputs from '../FormSlideInputs/FormSlideInputs'
import VisualMedia from '../VisualMedia/VisualMedia'
import FormSlidePlayerPresentation from './FormSlidePlayerPresentation'

export type FormSlidePlayerProps = {
  publishedFormSlide: PublishedFormSlide
  onMount: () => void
  onSubmit: (data: Record<string, string | string[]>) => void
}

const FormSlidePlayer: FC<FormSlidePlayerProps> = (props) => {
  return (
    <FormSlidePlayerPresentation
      {...props}
      useIsMobile={useIsMobile}
      VisualMedia={VisualMedia}
      FormSlideInputs={FormSlideInputs}
    />
  )
}

export default FormSlidePlayer
