import { FC } from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { PublishedSelectionOption, PublishedSelectionSlide } from '../../orval/loovPublic'
import SelectionSlidePlayerPresentation from './SelectionSlidePlayerPresentation'

type SelectionSlidePlayerProps = {
  publishedSelectionSlide: PublishedSelectionSlide
  onMount: () => void
  onSelect: (option: PublishedSelectionOption) => void
}
const SelectionSlidePlayer: FC<SelectionSlidePlayerProps> = (props) => {
  return <SelectionSlidePlayerPresentation {...props} useIsMobile={useIsMobile} />
}

export default SelectionSlidePlayer
